import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';

export const sit: IPartnerAppSettings = {
  cdnBaseUrl: 'https://dmsitafdcdn-cvcsa9cfabdubpez.z01.azurefd.net/',
  serviceEndPoints: [
    {
      clientId: 'c8955af6-b4a7-4e96-86b7-ad0930c25fa7',
      serviceEndPoint: 'https://dmsitafd.azurefd.net/'
    },
    {
      clientId: '99b7fa0a-a125-4f7a-abea-efb429bb8972',
      serviceEndPoint: 'https://oneprofileuatapipack.azurefd.net/api/v1/'
    },
    {
      clientId: '99b7fa0a-a125-4f7a-abea-efb429bb8972',
      serviceEndPoint: 'https://oneprofileuatapipack.azurefd.net/api/v2.0/'
    }
  ],
  DmAppConfiguration: {
    'cdnBaseUrl': 'https://dmsitafdcdn-cvcsa9cfabdubpez.z01.azurefd.net/',
    'dmServiceBaseUri': 'https://dmsitafd.azurefd.net/',
    'esxpBaseUrl': 'https://servicesint.azurewebsites.net/#/',
    'manageAttendeesUrl': 'https://axissitngweb.azurewebsites.net/Workshop/ViewAttendees/%s',
    'oneProfileBaseUrl': 'https://oneprofileuatapipack.azurefd.net/',
    'irisBaseUrl': 'https://iris-api-dev.azurewebsites.net/',
    'sourceClientIdForChronos': 901,
    'grmRoleDetailsStateName': 'grm-role-details-experience',
    'oneListUrlWithTPID': 'https://enterprisefeedback-int.azurewebsites.net/feedback?integrationType=SSD&appURL=%s&appItemId=%s&title=%s&TPID=%s',
    'oneListUrl': 'https://enterprisefeedback-int.azurewebsites.net/feedback?integrationType=SSD&appURL=https://servicesint.azurewebsites.net/%23/supportdelivery/requestdetails/%s&appItemId=%s&title=%s',
    'workshopServiceCenterUrl': 'https://microsoft.sharepoint.com/teams/Service-Center/SitePages/WorkshopManagement.aspx',
    'createLabUrl': 'https://mslearningcampus.com/User/Login?returnUrl=%2FPages%2Fms-learningcampus',
    'createCertificateUrl': 'https://portal.eventbuilder.com/login?returnUrl=%2Fdashboard',
    'servicesHubUrl': 'https://serviceshub.microsoft.com/home',
    'SNOWUrl': 'https://microsoft.service-now.com/sp/',
    'workshopOneNoteUrl': 'https://nam06.safelinks.protection.outlook.com/?url=https%3A%2F%2Fmicrosoft.sharepoint.com%2Fteams%2FWW_Service_Center_Globalization%2F_layouts%2FOneNote.aspx%3Fid%3D%252Fteams%252FWW_Service_Center_Globalization%252FOneNote%2520Library%252FWW%2520SC%2520Delivery%2520Guidance%26wd%3Dtarget%2528Premier%2520Services%252FWorkshop%2520Management%2520Global%2529&data=04%7C01%7Chiralpatel%40microsoft.com%7Ccfbce9b2b5bd40dcca9a08d8c235b5c1%7C72f988bf86f141af91ab2d7cd011db47%7C0%7C0%7C637472883225302783%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C1000&sdata=hNmY3eWPsSYFEj6c%2F97uEtcmDz3yJnffEbMgZwfL94Y%3D&reserved=0',
    'enableSandboxPage': true,
    'svcSpecialistAccessRequestTeamsDetails': {
      'teamName': 'SD Access Requests',
      'teamChannelName': 'SvcSpecialist Access - dev'
    },
    'rmexBaseUrl': 'https://professionalservicesint.microsoft.com/rmsvcsit/api/',
    'rmexSubscriptionKey': 'da0ed7b1237d4183aa5884bbd02782ad',
    'appInsights-instrumentationKey': '6bf9cd13-7c83-4325-9d8b-0b275b92268d',
    'dynamicsBaseUrl': 'https://msxuat.crm.dynamics.com/'
  }
};
